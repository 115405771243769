import { AuthService } from './../services/auth.service';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { TranslateService } from '@ngx-translate/core';


@Injectable()
export class RequestInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthService,private _translate:TranslateService,private _ngxLoader:NgxUiLoaderService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      let current_lang=this._translate.currentLang==undefined?'fr':this._translate.currentLang;
      request = request.clone({headers: request.headers.set('applanguage', current_lang)});


       return next.handle(request).pipe(catchError(error => {
            try{
              this._ngxLoader.stopAll()
            }catch(err){
              console.log("No loader is runnig")
            }



            if(error.status===400){
              this._translate.get(error.error.message).subscribe((val)=>{
                Swal.fire(error.error.title,val, "error");
              });
            }else if(error.status===422){
              this._translate.get(error.error.message).subscribe((val)=>{
                Swal.fire(error.error.title, val, "error");
              });
            }else if(error.status=== 401){
              alert("unauthorized");
            }else if(error.status===409){
              Swal.fire(error.error.title, error.error.message, "error");
            }
            else if (error.status === 0) {
              alert("no internet connection");
            }

            return throwError(error);
        }));
    }
}
