import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './theme/layout/admin/admin.component';
import {AuthComponent} from './theme/layout/auth/auth.component';
import { AuthGuardService } from './theme/guards/auth-guard.service';

const routes: Routes = [
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
      },
      {
        path: 'login',
        loadChildren: () => import('./simbi/auth/auth-signin-v2/auth-signin-v2.module')
          .then(module => module.AuthSigninV2Module)
      },
      {
        path: 'authentication',
        loadChildren: () => import('./simbi/auth/auth-reset-password-v2/auth-reset-password-v2.module')
          .then(module => module.AuthResetPasswordV2Module)
      }
    ]
  },
  {
    path: '',
    component: AdminComponent,
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
      {
        path: 'dashboard/sale',
        canActivate:[AuthGuardService],
        data : { full_module_route :'default'},
        loadChildren: () => import('./simbi/home/dash-sale/dash-sale.module').then(module => module.DashSaleModule)
      },
      {
         path: 'changepassword',
         loadChildren: () => import('./simbi/changepassword/changepassword.module').then(module => module.ChangepasswordModule)
      },
      {
        path: 'subadmin',
        canActivate:[AuthGuardService],
        data : { full_module_route :'subadmin'},
        loadChildren: () => import('./simbi/manage/subadmin/subadmin.module').then(module => module.SubadminModule)
      },
      {
        path: 'menuitemcategory',
        canActivate:[AuthGuardService],
        data : { full_module_route :'menuitemcategory'},
        loadChildren: () => import('./simbi/master/menuitem-category/menuitem-category.module').then(module => module.MenuitemCategoryModule)
      },
      {
        path: 'zone',
        canActivate:[AuthGuardService],
        data : { full_module_route :'zone'},
        loadChildren: () => import('./simbi/master/zone/zone.module').then(module => module.ZoneModule)
      },
      {
        path: 'managerestaurant',
        loadChildren: () => import('./simbi/manage/managerestaurant/managerestaurant.module').then(module => module.ManagerestaurantModule)
      },
      {
        path: 'managecustomer',
        loadChildren: () => import('./simbi/manage/managecustomer/managecustomer.module').then(module => module.ManagecustomerModule)
      },
      {
        path: 'managedeliveryperson',
        loadChildren: () => import('./simbi/manage/managedeliveryperson/managedeliveryperson.module').then(module => module.ManageDeliveryPersonModule)
      },
      {
        path: 'report',
        loadChildren: () => import('./simbi/report/report.module').then(module => module.ReportModule)
      },
      {
        path: 'manageorder',
        loadChildren: () => import('./simbi/manage/manageorder/manageorder.module').then(module => module.ManageorderModule)
      },
      {
        path: 'admin_roles',
        loadChildren: () => import('./simbi/manage/roleandpermission/roleandpermission.module').then(module => module.RoleandpermissionModule)
      },
      {
        path: 'cuisine',
        canActivate:[AuthGuardService],
        data : { full_module_route :'cuisine'},
        loadChildren: () => import('./simbi/master/cuisine/cuisine.module').then(module => module.CuisineModule)
      },
    ]
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
