import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/timeoutWith'
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private _jwtHelper: JwtHelperService,private _router:Router) { }

  isAuthenticated(){
    let accessToken= localStorage.getItem('accessToken')
    return !this._jwtHelper.isTokenExpired(accessToken);
  }

  getUserInformation(){
    const decodedToken = this._jwtHelper.decodeToken(localStorage.getItem('accessToken'));
    console.log("decode token",decodedToken);
    return decodedToken;
  }

  logout(){
    localStorage.clear();
    this._router.navigate(['page/auth/login']);
  }
  getToken(){
   return localStorage.getItem('token')
  }
}
