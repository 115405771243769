import { Router } from '@angular/router';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import { Component, OnInit, Output,EventEmitter } from '@angular/core';
import {NgbDropdownConfig} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-nav-right',
  templateUrl: './nav-right.component.html',
  styleUrls: ['./nav-right.component.scss'],
  providers: [NgbDropdownConfig]
})
export class NavRightComponent implements OnInit {
  selected_laguage:any="fr";

  userName:any;
  constructor(public translate:TranslateService,
    private _router:Router) { }

  ngOnInit() {
    if(window.localStorage.getItem('userData')){
      this.userName=JSON.parse(window.localStorage.getItem('userData')).name;
    }

    if(window.localStorage.getItem('app_language')){
      this.selected_laguage=window.localStorage.getItem('app_language');

    }
   }

  languageChanged(){
    window.localStorage.setItem('app_language',this.selected_laguage);
    window.location.reload(true);
  }

  logout(){
    window.localStorage.clear();
    this._router.navigate(['/login'],{ replaceUrl: true });
  }

  changePassword(){
    this._router.navigate(['/changepassword'])
  }
}
