import {Injectable} from '@angular/core';

export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: Navigation[];
  module_route?:string;
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}

const NavigationItems = [
  {
    id: 'other',
    title: 'Home',
    type: 'group',
    icon: 'feather icon-align-left',
    children: [
      {
        id: 'dashboard-page',
        title: 'Dashboard',
        type: 'item',
        url: '/dashboard/sale',
        classes: 'nav-item',
        icon: 'feather icon-home',
        module_route:"default"

      }
    ]
  },
  {
    id: 'other',
    title: 'Management',
    type: 'group',
    icon: 'feather icon-align-left',
    children: [

      {
        id: 'subadmin-page',
        title: 'Subadmin',
        type: 'item',
        url: '/subadmin',
        classes: 'nav-item',
        icon: 'feather icon-user-check',
        module_route:"subadmin"

      },
      {
        id: 'managerestaurant',
        title: 'Restaurant',
        type: 'collapse',
        icon: 'feather icon-shopping-cart',
        children: [
          {
            id: 'restaurant',
            title: 'Restaurant',
            type: 'item',
            url: '/managerestaurant/restaurant',
            module_route:"managerestaurant/restaurant"
          }

        ]
      },
      {
        id: 'managecustomer',
        title: 'Customer',
        type: 'collapse',
        icon: 'feather icon-user',
        children: [
          {
            id: 'customer',
            title: 'Customer',
            type: 'item',
            url: '/managecustomer/customer',
            module_route:"managecustomer/customer"
          }

        ]
      },
      {
        id: 'managedeliveryperson',
        title: 'Delivery Person',
        type: 'collapse',
        icon: 'feather icon-aperture',
        children: [
          {
            id: 'deliveryperson',
            title: 'Delivery Person',
            type: 'item',
            url: '/managedeliveryperson/deliveryperson',
            module_route:"managedeliveryperson/deliveryperson"
          },
          {
            id: 'deliveryperson-management',
            title: 'Delivery Person Management',
            type: 'item',
            url: '/managedeliveryperson/deliverypersonmanagement',
            module_route:"managedeliveryperson/deliverypersonmanagement"
          }

        ]
      },
      {
        id: 'manageorder',
        title: 'Order',
        type: 'collapse',
        icon: 'feather icon-heart',
        children: [
          {
            id: 'order',
            title: 'Validation Pending Order',
            type: 'item',
            url: '/manageorder/order/validation-pending',
            module_route:"manageorder/order/validation-pending"
          },
          {
            id: 'order',
            title: 'Active Order',
            type: 'item',
            url: '/manageorder/order/confirmed_order',
            module_route:"manageorder/order/confirmed_order"
          },
          {
            id: 'order',
            title: 'Past Order',
            type: 'item',
            url: '/manageorder/order/past_order',
            module_route:"manageorder/order/past_order"
          }

        ]
      },
      {
        id: 'roleandpermission',
        title: 'Role & Permission',
        type: 'collapse',
        icon: 'feather icon-shield',
        children: [
          {
            id: 'modulesetting',
            title: 'Module Setting',
            type: 'item',
            url: '/admin_roles/module',
            module_route:"admin_roles/module"
          },
          {
            id: 'role',
            title: 'Role & Permission',
            type: 'item',
            url: '/admin_roles/role',
            module_route:"admin_roles/role"
          }
        ]


      }
    ]},
    {
    id: 'master',
    title: 'Master',
    type: 'group',
    icon: 'feather icon-align-left',
    children: [
      {

        id: 'master table',
        title: 'Master Tables',
        type: 'collapse',
        icon: 'feather icon-box',
        children:[
          {
            id: 'menuitem-category-page',
            title: 'Menuitem Category',
            type: 'item',
            url: '/menuitemcategory',
            module_route:"menuitemcategory"

          },
          {
            id: 'cuisine-page',
            title: 'Cuisine',
            type: 'item',
            url: '/cuisine',
            module_route:"cuisine"

          },
          {
            id: 'zone-page',
            title: 'Zone',
            type: 'item',
            url: '/zone',
            module_route:"zone"

          }
        ]
      }

    ]
  },
  {
    id: 'report',
    title: 'Report',
    type: 'group',
    icon: 'feather icon-align-left',
    children: [
      {

        id: 'paymentreport',
        title: 'Payment Settelment',
        type: 'collapse',
        icon: 'feather icon-aperture',
        children: [
          {
            id: 'codcollection-page',
            title: 'COD Collection',
            type: 'item',
            url: '/report/codcollection',
            module_route:"report/codcollection"
          },
          // {
          //   id: 'customerrefund-page',
          //   title: 'Customer Refund',
          //   type: 'item',
          //   url: '/report/customerrefund',
          //   module_route:"report/customerrefund"
          // },
          {
            id: 'deliverypayment-page',
            title: 'Delivery Boy Payment',
            type: 'item',
            url: '/report/deliverypayment',
            module_route:"report/deliverypayment"
          },
          {
            id: 'restaurantpayment-page',
            title: 'Restaurant Payment',
            type: 'item',
            url: '/report/restaurantpayment',
            module_route:"report/restaurantpayment"
          }
        ]
      },
      {

        id: 'turnoverreport',
        title: 'Turnover Report',
        type: 'collapse',
        icon: 'feather icon-aperture',
        children: [
          {
            id: 'restaurant-turnover-page',
            title: 'Restaurant turnover',
            type: 'item',
            url: '/report/restaurantturnover',
            module_route:"report/restaurantturnover"
          }
        ]
      },
      {
        id: 'setting',
        title: 'Setting',
        type: 'collapse',
        icon: 'feather icon-settings',
        children: [
          {
            id: 'customer-support-page',
            title: 'Customer Support',
            type: 'item',
            url: '/report/customersupport',
            module_route:"report/customersupport"
          }
        ]}
    ]
  }
];

@Injectable()
export class NavigationItem {
  public get() {
    return NavigationItems;
  }
}
