import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs'
import { AuthService } from 'src/app/simbi/services/auth.service';
@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  permissionMap:any;
  constructor(private _router:Router,private _authService:AuthService) {

  }






  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    this.permissionMap = new Map(JSON.parse(window.localStorage.getItem('userPermission')));

    const full_module_route= next.data.full_module_route;

    if(this._authService.isAuthenticated()){
      if(full_module_route=='default'){
        return true
      }else{

        if(this.permissionMap.get(full_module_route)){
          return true
        }else{
          return false
        }
      }
    }else{
      this._router.navigate(['/login'])
      return false

    }


    // return true

    // if(this._authService.isAuthenticated()){
    //   if(this.privileges[access]){
    //     return true
    //   }else{
    //     this._router.navigate(['/dashboard/sale']);
    //     return true
    //   }
    // }else{
    //   this._router.navigate(['/login']);
    //   window.localStorage.clear();
    //   return true;
    // }
    // if (!this._authService.isAuthenticated()) {
    //     this._router.navigate(['/login']);
    //     window.localStorage.clear();
    //     return false;
    //  }else{

    //     if(this.privileges[access]){
    //       return false
    //     }else{
    //       this._router.navigate(['/dashboard/sale']);
    //       return true
    //     }
    //  }
  }

}
